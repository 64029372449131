import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import './styles/style.css'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

const canvas = document.getElementById('myCanvas')
const context = canvas.getContext('2d')

canvas.width = 1440
canvas.height = 1440

const frameCount = 360
const currentFrame = (index) =>
  `https://essentry.netlify.app/webp/Essentry-Animation${index
    .toString()
    .padStart(3, '0')}.webp`

const images = []
const airpods = { frame: 0 }

// Preload images
for (let i = 0; i < frameCount; i++) {
  const img = new Image()
  img.src = currentFrame(i)
  images.push(img)
}

gsap.to(airpods, {
  frame: frameCount - 1,
  snap: 'frame',
  ease: 'none',
  scrollTrigger: {
    trigger: '.section_layout30',
    start: 'top top',
    end: 'bottom bottom',
    scrub: 1,
  },
  onUpdate: render,
})

images[0].onload = render

function render() {
  context.clearRect(0, 0, canvas.width, canvas.height)
  context.drawImage(images[airpods.frame], 0, 0)
}

let animating = false
function scrollToMarker(marker) {
  if (animating) return
  animating = true
  gsap.to(window, {
    scrollTo: marker,
    duration: 2,
    ease: 'power1.inOut',
    onComplete: () => {
      animating = false
      // Refresh triggers in case element positions have shifted.
      ScrollTrigger.refresh()
    },
  })
}

/* Prevent auto-scroll on initial load */
let initialLoad = true

setTimeout(() => {
  initialLoad = false
}, 500) // Adjust delay as needed

/* DOWNWARD TRANSITIONS */
// 1. When marker0 hits the top, auto-scroll to marker1.
ScrollTrigger.create({
  trigger: '#marker0',
  start: 'top top',
  onEnter: () => {
    if (!initialLoad) scrollToMarker('#marker1')
  },
  // markers: true,
})

// 2. When marker1 goes 30px above the viewport, auto-scroll to marker2.
ScrollTrigger.create({
  trigger: '#marker1',
  start: 'top -30',
  onEnter: () => {
    if (!initialLoad) scrollToMarker('#marker2')
  },
  // markers: true,
})

// 3. When marker2 goes 30px above the viewport, auto-scroll to marker3.
ScrollTrigger.create({
  trigger: '#marker2',
  start: 'top -30',
  onEnter: () => {
    if (!initialLoad) scrollToMarker('#marker3')
  },
  // markers: true,
})

/* UPWARD TRANSITIONS */
// 1. When scrolling up and marker1 comes to 40px below the top, auto-scroll back to marker0.
ScrollTrigger.create({
  trigger: '#marker1',
  start: 'top 40', // offset changed to 40 to create a gap from downward triggers
  onLeaveBack: () => {
    if (!initialLoad) scrollToMarker('#marker0')
  },
  // markers: true,
})

// 2. When marker2 comes to 40px below the top while scrolling up, auto-scroll back to marker1.
ScrollTrigger.create({
  trigger: '#marker2',
  start: 'top 40',
  onLeaveBack: () => {
    if (!initialLoad) scrollToMarker('#marker1')
  },
  // markers: true,
})

// 3. When marker3 comes to 40px below the top while scrolling up, auto-scroll back to marker2.
ScrollTrigger.create({
  trigger: '#marker3',
  start: 'top 40',
  onLeaveBack: () => {
    if (!initialLoad) scrollToMarker('#marker2')
  },
  // markers: true,
})

/* ACTIVE ITEMS */
// This function updates the opacity of your items based on the activeCount.
// activeCount = 0 means no items active; 1 means the first item active;
// 2 means the first two active; 3 means all three active.
function updateActiveItems(activeCount) {
  const items = document.querySelectorAll('.layout30_item')
  items.forEach((item, index) => {
    gsap.to(item, { opacity: index < activeCount ? 1 : 0.6, duration: 0.5 })
  })
}

// Trigger for marker0: when marker0 reaches the top, activate the first item.
// When scrolling up past marker0 (onLeaveBack), deactivate all items.
ScrollTrigger.create({
  trigger: '#marker0',
  start: 'top top',
  onEnter: () => updateActiveItems(1),
  onLeaveBack: () => updateActiveItems(0),
  // markers: true, // Uncomment to debug trigger positions.
})

// Trigger for marker1: when marker1 reaches the top, activate the first two items.
// When scrolling up past marker1, revert back to only the first item active.
ScrollTrigger.create({
  trigger: '#marker1',
  start: 'top top',
  onEnter: () => updateActiveItems(2),
  onLeaveBack: () => updateActiveItems(1),
  // markers: true,
})

// Trigger for marker2: when marker2 reaches the top, activate all three items.
// When scrolling up past marker2, revert back to two items active.
ScrollTrigger.create({
  trigger: '#marker2',
  start: 'top top',
  onEnter: () => updateActiveItems(3),
  onLeaveBack: () => updateActiveItems(2),
  // markers: true,
})

// Ensure the progress starts at 0%
gsap.set('.layout30_progress', { height: '0%' })

// Animate from 0% to 33% between marker0 and marker1.
gsap.fromTo(
  '.layout30_progress',
  { height: '0%' },
  {
    height: '33%',
    ease: 'none',
    immediateRender: false,
    scrollTrigger: {
      trigger: '#marker0',
      start: 'top+=4rem top', // adjust offset if necessary
      endTrigger: '#marker1',
      end: 'top top',
      scrub: true,
    },
  }
)

// Animate from 33% to 66% between marker1 and marker2.
gsap.fromTo(
  '.layout30_progress',
  { height: '33%' },
  {
    height: '66%',
    ease: 'none',
    immediateRender: false,
    scrollTrigger: {
      trigger: '#marker1',
      start: 'top+=4rem top',
      endTrigger: '#marker2',
      end: 'top top',
      scrub: true,
    },
  }
)

// Animate from 66% to 100% between marker2 and marker3.
gsap.fromTo(
  '.layout30_progress',
  { height: '66%' },
  {
    height: '100%',
    ease: 'none',
    immediateRender: false,
    scrollTrigger: {
      trigger: '#marker2',
      start: 'top+=4rem top',
      endTrigger: '#marker3',
      end: 'top top',
      scrub: true,
    },
  }
)

// Debounce helper (optional, to limit refresh calls during rapid resize events)
let resizeTimer
window.addEventListener('resize', () => {
  clearTimeout(resizeTimer)
  resizeTimer = setTimeout(() => {
    ScrollTrigger.refresh()
  }, 250)
})
